<template>
  <BModal
    :active="showAuthComponent"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    :can-cancel="['outside', 'escape']"
    @close="$emit('close')"
  >
    <LoginModalContent @close="$emit('close')" />
  </BModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal } from 'buefy/dist/esm/modal';
export default {
  name: 'ModalIndex',
  components: {
    BModal,
    LoginModalContent: () => import('@/views/Auth/LoginModalContent'),
  },
  computed: {
    ...mapGetters('auth', ['showAuthComponent']),
  },
  props: {},
};
</script>
<style lang="scss">
.modal-content,
.modal-card {
  overflow: hidden;
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 30px) !important;
  }
  @media screen and (max-width: 440px) {
    max-height: calc(100vh - 50px) !important;
    margin-top: 50px !important;
  }
}
</style>
